import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Axios from "axios";
Vue.prototype.$axios = Axios;

import VueIziToast from 'vue-izitoast';
import 'izitoast/dist/css/iziToast.min.css';
Vue.use(VueIziToast);

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

Vue.config.productionTip = false

if (process.env.MIX_APP_ENV === 'production') {
  Vue.config.devtools = false;
  Vue.config.debug = false;
  Vue.config.silent = true;
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
