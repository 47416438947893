import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/Index.vue'),
    children: [
      {
        path: '',
        name: "uvod",
        component: () => import("@/views/Uvod.vue")
      },
      {
        path: "ponuka-kamena",
        name: "ponuka-kamena",
        component: () => import("@/views/PonukaKamena.vue"),
        children: [
          {
            path:":kategoria",
            name:"kategoria",
            children: [
              {
                path:":subkategoria",
                name:"subkategoria",
              }
            ]
          }
        ]
      },
      {
        path: "kamen/:kamenSlug",
        name: "kamenDetail",
        component: () => import("@/views/KamenDetail.vue"),
      },
      {
        path: "realizacie",
        name: "realizacie",
        component: () => import("@/views/Realizacie.vue")
      },
      {
        path: "gdpr",
        name: "gdpr",
        component: () => import("@/views/Gdpr.vue")
      },
      {
        path: "kontakt",
        name: "kontakt",
        component: () => import("@/views/Kontakt.vue")
      }
    ]
  },
  {
    path:'*',
    name:"all",
    redirect: {name:'uvod'}
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 };
  }
});
export default router
