<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  metaInfo: {
      title: 'Predaj prírodného kameňa',
      titleTemplate: '%s | predajkamena.sk',
      charset: 'utf-8',
      meta: [
        { property:"og:image", content:"http://predajkamena.sk/images/present_img.jpg" },
        { property:"og:locale", content:"sk_SK" },
        { property:"twitter:image", content:"http://predajkamena.sk/images/present_img.jpg" },
      ]
    }
}
</script>